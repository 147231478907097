import React, { Fragment } from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const TweetBox = () => (
	<Fragment>
		<div className='tweetbox'>
			<TwitterTimelineEmbed
				sourceType='profile'
				screenName='ASGSRStudents'
				options={{ height: 600 }}
				placeholder={<h3 className='mt-5'>Loading twitter timeline...</h3>}
			/>
		</div>
	</Fragment>
);

export default TweetBox;
