import React, { Fragment } from 'react';
import events from '../../events.json';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';

const fd = (d: string) =>
	new Intl.DateTimeFormat('en-US', {
		weekday: 'short',
		day: '2-digit',
		month: 'short',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit'
	}).format(new Date(d));

const Events = () => (
	<Fragment>
		<h3 className='display-7 raleway-700'>News and Events</h3>
		<Carousel>
			{events.length > 0 &&
				events.map(({ startDate, endDate, desc, link, location, name, img }) => (
					<Carousel.Item>
						<Card key={name} className='mx-auto event br my-3'>
							{img && <Card.Img variant='top' src={img} />}
							<Card.Body>
								<Card.Title className='raleway-700' style={{ fontSize: '1.75rem' }}>
									{name}
								</Card.Title>
								<Card.Text>{desc}</Card.Text>
								<Card.Text>
									Location: <strong>{location}</strong>
									<br />
									{startDate && (
										<span>
											Date: <strong>{fd(startDate)}</strong> to <strong>{fd(endDate)}</strong>
										</span>
									)}
								</Card.Text>
								<Card.Link href={link}>Click here to know more!</Card.Link>
							</Card.Body>
						</Card>
					</Carousel.Item>
				))}
			{events.length <= 0 && <span>No upcoming events.</span>}
		</Carousel>
	</Fragment>
);

export default Events;
