import React from 'react';
import Events from '../components/Events';
import Layout from '../components/Layout';
import Partners from '../components/Partners';
import Slideshow from '../components/Slideshow';
import TweetBox from '../components/TweetBox';

const IndexPage = () => (
	<Layout banner>
		<div className='row my-3 my-md-5 justify-content-between align-items-center'>
			<div className='col-md-7 col-lg-5 px-3 order-1'>
				<h1 className='raleway-700 display-3'>Welcome!</h1>
				<p>
					Welcome to the website for ASGSR students! We are a multidisciplinary group of science/engineering
					students across the United States and world who participate (or are just interested in!)
					microgravity sciences. We participate in education outreach, political advocacy, and professional
					development through the framework of our parent organization, the American Society for Gravitational
					and Space Research.
				</p>
				<p>
					For more information on our parent chapter, see{' '}
					<a href='https://asgsr.org/' target='_blank'>
						asgsr.org
					</a>
					. For more information on ASGSR’s student spaceflight challenge, see the main page for the{' '}
					<a href='https://asgsr.org/souza/' target='_blank'>
						Ken Souza Memorial Spaceflight Competition
					</a>
					.
				</p>
			</div>
			<div className='col-md-5 my-3 order-0 order-md-2'>
				<Slideshow />
			</div>
		</div>

		<div className='row d-flex justify-content-between'>
			<div className='col-md-6'>
				<Events />
			</div>
			<div className='my-4 my-md-0 col-md-6 col-lg-4'>
				<h3 className='display-7 raleway-700'>Stay in Touch</h3>
				<div className='d-flex flex-column flex-wrap justify-content-evenly align-items-stretch h-100'>
					<TweetBox />
					<span className='mx-auto'>
						<a
							className='btn btn-lg btn-outline-dark fw-bold border border-dark border-2'
							href='https://asgsr.us20.list-manage.com/subscribe?u=295592de8d4d44479539838a5&id=ea7aa995fe'
							target='_blank'
						>
							Subscribe to our newsletter
						</a>
					</span>
				</div>
			</div>
		</div>

		<div className='row my-3 my-md-5 justify-content-between align-items-center'>
			<h2 className='raleway-700 display-7'>Our Partners</h2>
			<Partners />
		</div>
	</Layout>
);

export default IndexPage;
