import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Partners = () => (
	<section>
		{/* For xs, sm, and xl+ screens. */}
		<div className='d-flex d-md-none d-lg-none d-xl-flex flex-wrap justify-content-evenly align-items-center'>
			<a href='https://www.unoosa.org/' target='_blank'>
				<StaticImage
					src='../images/partners/unoosa-1.png'
					alt='UNOOSA'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={250}
				/>
			</a>
			<a href='https://sites.google.com/site/studentelgra/' target='_blank'>
				<StaticImage
					src='../images/partners/selgra.png'
					alt='SELGRA'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={250}
				/>
			</a>
			<a href='https://fairchildgarden.org/gbe/' target='_blank'>
				<StaticImage
					src='../images/partners/fairchild-gbe.gif'
					alt='Fairchild Growing Beyond Earth'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={250}
				/>
			</a>
			<a href='https://eccbn.org/' target='_blank'>
				<StaticImage
					src='../images/partners/eccbn.png'
					alt='ECCBN'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={250}
				/>
			</a>
			<a href='https://spacegeneration.org/' target='_blank'>
				<StaticImage
					src='../images/partners/sgac.png'
					alt='SGAC'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={250}
				/>
			</a>
			<a href='https://www.amsro.org/' target='_blank'>
				<StaticImage
					src='../images/partners/amsro.png'
					alt='AMSRO'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={250}
				/>
			</a>
		</div>

		{/* For md screens. */}
		<div className='d-none d-md-flex d-lg-none flex-wrap justify-content-evenly align-items-center'>
			<a href='https://www.unoosa.org/' target='_blank'>
				<StaticImage
					src='../images/partners/unoosa-1.png'
					alt='UNOOSA'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={150}
				/>
			</a>
			<a href='https://sites.google.com/site/studentelgra/' target='_blank'>
				<StaticImage
					src='../images/partners/selgra.png'
					alt='SELGRA'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={150}
				/>
			</a>
			<a href='https://fairchildgarden.org/gbe/' target='_blank'>
				<StaticImage
					src='../images/partners/fairchild-gbe.gif'
					alt='Fairchild Growing Beyond Earth'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={150}
				/>
			</a>
			<a href='https://eccbn.org/' target='_blank'>
				<StaticImage
					src='../images/partners/eccbn.png'
					alt='ECCBN'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={150}
				/>
			</a>
			<a href='https://spacegeneration.org/' target='_blank'>
				<StaticImage
					src='../images/partners/sgac.png'
					alt='SGAC'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={150}
				/>
			</a>
			<a href='https://www.amsro.org/' target='_blank'>
				<StaticImage
					src='../images/partners/amsro.png'
					alt='AMSRO'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={150}
				/>
			</a>
		</div>

		{/* For lg screens. */}
		<div className='d-none d-lg-flex d-xl-none flex-wrap justify-content-evenly align-items-center'>
			<a href='https://www.unoosa.org/' target='_blank'>
				<StaticImage
					src='../images/partners/unoosa-1.png'
					alt='UNOOSA'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={225}
				/>
			</a>
			<a href='https://sites.google.com/site/studentelgra/' target='_blank'>
				<StaticImage
					src='../images/partners/selgra.png'
					alt='SELGRA'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={225}
				/>
			</a>
			<a href='https://fairchildgarden.org/gbe/' target='_blank'>
				<StaticImage
					src='../images/partners/fairchild-gbe.gif'
					alt='Fairchild Growing Beyond Earth'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={225}
				/>
			</a>
			<a href='https://eccbn.org/' target='_blank'>
				<StaticImage
					src='../images/partners/eccbn.png'
					alt='ECCBN'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={225}
				/>
			</a>
			<a href='https://spacegeneration.org/' target='_blank'>
				<StaticImage
					src='../images/partners/sgac.png'
					alt='SGAC'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={225}
				/>
			</a>
			<a href='https://www.amsro.org/' target='_blank'>
				<StaticImage
					src='../images/partners/amsro.png'
					alt='AMSRO'
					formats={['auto', 'webp']}
					placeholder='dominantColor'
					width={225}
				/>
			</a>
		</div>
	</section>
);

export default Partners;
